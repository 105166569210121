import jQuery from "jquery";
import jsZip from 'jszip';
import pdfmake from 'pdfmake';
import DataTable from 'datatables.net-bs4';
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis.mjs';
import 'datatables.net-buttons/js/buttons.html5.mjs';
import 'datatables.net-buttons/js/buttons.print.mjs';

window.JSZip = jsZip;
// Make DataTable available globally
frappe.transformTableToDataTable = function(id) {
        // Transform template in DataTable
    let table = new DataTable('#' + id, {
        dom: 'B<"toolbar">lfrtip',
        fixedHeader: true,
        buttons: [
            'copy',
            'excel',
            'csv'
        ]
    });

    $('#' + id + '_wrapper .buttons-html5').removeClass('dt-button');
    $('#' + id + '_wrapper .buttons-html5').addClass('btn btn-default btn-xs');
    $("div.toolbar").html('<br><br>');

}

